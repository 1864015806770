import React, { useCallback } from 'react';
import { navigate } from 'gatsby';
import slugify from 'slugify';

import SimpleResults from '../../../views/simple-results';
import { getQueryParams } from '../../../helpers/helper-methods';
import { ACTIVITY_CATEGORY_FILTER_INITIAL_VALUES, ACTIVITY_CATEGORY_OPTIONS } from '../../../constans/activity';
import { activityGetter } from '../../../helpers/getter';
import { cleanedUrl } from '../../../constans/locations';

function ActivitiesAdvancedSearchResults({ location }) {
  const { search } = location;
  const { name, ...ids } = getQueryParams(search);

  const getInitialFilter = useCallback(() => {
    const initialFilter = ACTIVITY_CATEGORY_FILTER_INITIAL_VALUES;
    if (ids.categoriesFilters?.length) {
      const { categoriesFilters } = ids;
      categoriesFilters.forEach((filter) => {
        initialFilter[filter] = true;
      });
    }
    return initialFilter;
  }, [ids.categoriesFilters]);

  return (
    <SimpleResults
      onCardClick={(title, city, region, country, urlDestination, urlName, event) => {
        const cityPart = slugify(`${urlDestination || city?.name || region?.name || country?.name}`);
        const propertyPart = slugify(`${urlName || title}`);
        const href = cleanedUrl(`/activities/${cityPart}/${propertyPart}/`);
        event.preventDefault();
        if (event.ctrlKey) return window.open(href, '_blank');
        navigate(href);
      }}
      initialFilterValues={getInitialFilter()}
      filterOptions={[
        {
          name: 'values',
          options: ACTIVITY_CATEGORY_OPTIONS,
        },
      ]}
      emptyMessage="There is no Activity associated with this Destination. Please, try another one."
      getTitle={(count) => {
        if (name?.length && count) return `${count} Activities`;
      }}
      getter={(
        filters,
      ) => activityGetter({
        ...filters, ids,
      })}
      name={name}
      ids={ids}
      location={location}
    />
  );
}

export default ActivitiesAdvancedSearchResults;
